<template>
  <b-row class="rounded-lg bg-white p-2 my-2 pb-5 shadow-lg">
    <b-col cols="12">
      <p class="text-center py-2 employee-info-title">APPLY NOW</p>
      <hr />
      <b-row>
        <b-col>
          <b-form-group
            id="input-group-2"
            label="First Name"
            label-for="FirstName"
          >
            <b-form-input
              id="FirstName"
              v-model="form.first_name"
              placeholder="Enter your First Name"
              required
              class="bg-light input-color"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            id="input-group-2"
            label="Last Name"
            label-for="lastname"
          >
            <b-form-input
              id="lastname"
              v-model="form.last_name"
              placeholder="Enter your last name"
              required
              class="bg-light input-color"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group id="input-group-2" label="Address" label-for="Address">
            <b-form-input
              id="input-2"
              v-model="form.address"
              placeholder="Address"
              required
              class="bg-light input-color"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            id="input-group-2"
            label="Contact No."
            label-for="Contact No."
          >
            <b-form-input
              id="input-2"
              v-model="form.phone"
              placeholder="Contact No."
              required
              class="bg-light input-color"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6">
          <b-form-group id="input-group-2" label="Email" label-for="email">
            <b-form-input
              id="input-1"
              v-model="form.email"
              type="email"
              placeholder="Enter email"
              require
              class="bg-light input-color"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row
        v-for="(skill, index) in form.skills"
        :key="index"
        class="d-flex flex-row align-items-center"
      >
        <b-col cols="12" md="6">
          <b-form-group id="input-group-2" label="Skill" label-for="Skill">
            <v-select
              v-model="skill.skill_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="skills"
              label="skill"
              :reduce="(skill) => skill.id"
              placeholder="Select Skill"
              class="search-input bg-white"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="5">
          <b-form-group
            id="input-group-2"
            label="Skill Level"
            label-for="Skill Level"
          >
            <v-select
              v-model="skill.skill_level"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="options"
              :reduce="(option) => option.title"
              label="title"
              placeholder="Select Skill Level"
              class="search-input bg-white"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="1" class="text-center p-0 m-0">
          <button
            @click.prevent="remove"
            class="btn btn-sm btn-danger"
            v-if="form.skills.length > 1"
          >
            X
          </button>
          <button
            v-if="skills.length > form.skills.length && index === form.skills.length - 1"
            @click.prevent="addMore"
            class="btn-sm btn btn-success shadow-lg"
            style="margin-left: 5px;"
          >
           +
          </button>
        </b-col>
      </b-row>

      <b-row>
        <b-col class="text-center">
          <div class="file">
            <label for="input-file">
              <img
                src="@/assets/images/icons/upload-cv.png"
                alt="upload-cv"
                class="img-fluid"
              />
            </label>
            <input @change="handleFile($event)" id="input-file" type="file" />
          </div>
          <div>
            <p v-if="fileMsg" class="text-danger">{{ fileMsg }}</p>
            <p v-else class="text-success">{{ selectedFile }}</p>
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col class="d-flex flex-row justify-content-between">
          <b-row>
            <b-col v-if="$can('apply', 'Job Post')">
              <b-button
                @click.prevent="handleSubmit"
                class="mx-1 px-4 send-btn"
              >
                Apply
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import { getLogedInUser } from "@/utils/auth";
import { toast } from "@/utils/toast";
import { isEmpty } from "@/utils/validate";
import {
BButton,
BCard,
BCol,
BForm,
BFormCheckbox,
BFormCheckboxGroup,
BFormDatepicker,
BFormGroup,
BFormInput,
BFormSelect,
BFormTextarea,
BLink,
BRow,
} from "bootstrap-vue";
import vSelect from "vue-select";
export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormCheckboxGroup,
    BFormDatepicker,
    BFormCheckbox,
    BButton,
    BCard,
    BFormTextarea,
    BLink,
    vSelect,
  },
  data() {
    return {
      fileMsg: "",
      selectedFile:"",
      skills: [],
      file: null,
      form: {
        first_name: "",
        last_name: "",
        address: "",
        phone: "",
        email: "",
        skills: [
          {
            skill_id: "",
            skill_level: "",
          },
        ],
        // file: "",
      },
      options: [{ title: "Low" }, { title: "Medium" }, { title: "High" }],
      logedInUser: JSON.parse(getLogedInUser()),
    };
  },
  created() {
    this.getSkills();
  },
  mounted() {
    if (this.logedInUser.roles[0].name == "employee") {
      this.form.first_name = this.logedInUser.first_name;
      this.form.last_name = this.logedInUser.last_name;
      this.form.address = this.logedInUser.address;
      this.form.phone = this.logedInUser.phone;
      this.form.email = this.logedInUser.email;
    }
  },
  methods: {
    getSkills() {
      this.$store.dispatch("skill/getSkills", this.query).then((response) => {
        this.skills = response.data;
      });
    },
    addMore() {
      this.form.skills.push({
        skill_id: "",
        skill_level: "",
      });
    },
    remove(index) {
      if (this.form.skills.length > 1) {
        this.form.skills.splice(index, 1);
      }
    },

    handleFile(e) {
      this.file = e.target.files[0];
      // if (this.file.size > 5000000) {
      //   this.file = null;
      //   this.fileMsg = "file should not be more than 5mb larger";
      // }

      if (
        ["application/pdf", "docx", "doc"].includes(this.file.type) &&
        this.file.size < 5000000
      ) {
     
        this.selectedFile =this.file.name;
      } else {
        this.file = null;
        this.fileMsg =
          "File type should be pdf, doc, docx and file size should be less than 5 mb";
          toast(
          "Validation Error",
          "AlertTriangleIcon",
          "danger",
          "File type should be pdf, doc, docx and file size should be less than 5 mb"
        );
      }
    },
    handleSubmit() {
      if (isEmpty(this.form)) {
        toast(
          "Validation Error",
          "AlertTriangleIcon",
          "danger",
          "Please fill all the form"
        );
      } else {
        this.form.job_post_id = this.$route.params.id;

        let formData = new FormData();
        // bind form data by key
        Object.keys(this.form).forEach((key) => {
          formData.append(key, this.form[key]);
        });
        formData.append("file", this.file);
        this.$store
          .dispatch("job/applyJob", formData)
          .then((res) => {
            if (res) {
              toast(
                "Success",
                "CheckCircleIcon",
                "success",
                "Your application has been submitted successfully"
              );
              this.$router.push({ name: "job-post" });
            }
          })
          .catch((err) => {
            toast(
              "Error",
              "AlertTriangleIcon",
              "danger",
              "Something went wrong"
            );
          });
      }
    },
  },
};
</script>

<style scoped>
.update-btn {
  background: linear-gradient(315deg, #01185e 0%, #264296 44.92%, #7190ef 100%);
  border-radius: 5px;
  color: white;
}

[dir] .table th,
[dir] .table td {
  border-top: 0 !important;
}

.head-line {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  line-height: 85px;
  letter-spacing: 0.01em;

  /* Text main */

  color: #515b60;
}

.description {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  text-align: justify;

  /* Text main */

  color: #515b60;
}

.paragraph {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: justify;

  /* t2 */

  color: rgba(21, 28, 37, 0.8);
}

.employee-info-title {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  line-height: 85px;

  /* Gradient */

  background: -webkit-linear-gradient(#01185e, #264296, #7190ef);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.short-description {
  /* 18 bold */

  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height */

  text-align: justify;

  /* Gradient */

  background: -webkit-linear-gradient(#01185e, #264296, #7190ef);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/*=================================================================*/

.file {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.file > input[type="file"] {
  display: none;
}

.file > label {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.inner-title {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: justify;
  color: #a8adaf;
}

.send-btn {
  background: linear-gradient(315deg, #01185e 0%, #264296 44.92%, #7190ef 100%);

  border-color: transparent !important;
  background: linear-gradient(315deg, #01185e 0%, #264296 44.92%, #7190ef 100%);
}

@keyframes pulse {
  0% {
    color: hsl(48, 100%, 67%);
  }

  50% {
    color: hsl(48, 100%, 38%);
  }

  100% {
    color: hsl(48, 100%, 67%);
  }
}

.rmv-btn {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  border-color: #ea5455 !important;
  background-color: #ea5455 !important;
  color: white;
}

.add-btn {
  /* 14 bold */

  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */

  /* Gradient */

  background: linear-gradient(#01185e 0%, #264296 44.92%, #7190ef 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.input-color {
  color: #01185e;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
